import { Col, Row, Typography } from "antd";
import React from "react";
import PPMSByRouteChart from "../../components/PPMSByRouteChart";
import PPMSByAirlineChart from "../../components/PPMSByAirlineChart";
import PayingPaxProportion from "../../components/PayingPaxProportion";
import LoadFactor from "../../components/LoadFactor";
import Top10NIP from "../../components/Top10NIP";
import { useSelector } from "react-redux";
import { useDashboardOverview } from "../../../../hooks/useDashboardV2";
import NotFoundData from "../../../../components/NotFoundData";

const Stakeholders = () => {
  const { filterPage: filterValue } = useSelector((state) => state.apps);
  const [data, loading] = useDashboardOverview({
    route: filterValue.route,
    year: filterValue.year,
    month: filterValue.months,
    filter: filterValue,
    view: "payingpax",
  });

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: 8,
        // background: "salmon",
      }}
    >
      <div id="card" style={{ flex: 1.7 }}>
        <Row gutter={8} style={{ height: "100%" }}>
          <Col lg={{ span: 12 }} style={{ height: "inherit" }}>
            <div style={{ height: "inherit" }}>
              {data?.routes?.data.length === 0 ? (
                <NotFoundData />
              ) : (
                <PPMSByRouteChart
                  title={"Paying Pax Market Share By Route"}
                  dataSource={data?.routes}
                  loading={loading}
                />
              )}
            </div>
          </Col>
          <Col lg={{ span: 12 }} style={{ height: "inherit" }}>
            <div style={{ height: "inherit" }}>
              {data?.payingPax === null ? (
                <NotFoundData />
              ) : (
                <PayingPaxProportion
                  dataSource={data?.payingPax}
                  loading={loading}
                  title={"Paying Pax Proportion"}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
      <div id="last" style={{ flex: 1.7 }}>
        <Row gutter={8} style={{ height: "100%" }}>
          <Col lg={{ span: 8 }} style={{ height: "inherit" }}>
            <div style={{ height: "inherit" }}>
              {data?.airlines?.length === 0 ? (
                <NotFoundData />
              ) : (
                <PPMSByAirlineChart
                  title={"Paying Pax Market Share By Airline"}
                  loading={loading}
                  dataSource={data?.airlines}
                />
              )}
            </div>
          </Col>
          <Col lg={{ span: 6 }} style={{ height: "inherit" }}>
            <div style={{ height: "inherit" }}>
              {data?.airlines?.length === 0 &&
              data?.routes?.data.length === 0 ? (
                <NotFoundData />
              ) : (
                <LoadFactor
                  title={"Load Factor"}
                  dataSource={data}
                  loading={loading}
                />
              )}
            </div>
          </Col>
          <Col lg={{ span: 10 }} style={{ height: "inherit" }}>
            <div style={{ height: "inherit" }}>
              {data?.nationalities?.length === 0 ? (
                <NotFoundData />
              ) : (
                <Top10NIP
                  route={filterValue.route}
                  loading={loading}
                  dataSource={data?.nationalities}
                  title={
                    "Top 10 Nationality International Passengers (In Million)"
                  }
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Stakeholders;
