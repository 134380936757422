import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  Layout,
  Row,
  Col,
  Divider,
  Typography,
  Button,
  DatePicker,
} from "antd";
import { useFontSize } from "../hooks/useDimension";
import { createUseStyles } from "react-jss";
import Config from "../config/env";
import MultiSelectComponent from "../components/CustomSelect/MultiSelect";
// import { formatedOptions } from "../../helper/formatedOptions";
import { useDispatch, useSelector } from "react-redux";
import { setActivePage } from "../redux";
import { useLocation } from "react-router";
import { setFilterPage } from "../redux/reducer/apps";
import dayjs from "dayjs";
import { formatedOptions } from "../helper/formatedOptions";
const { colorPrimary, colorSecondary } = Config;
const { Header } = Layout;
const { RangePicker } = DatePicker;

const useStyles = createUseStyles({
  container: {
    background: "white",
    padding: 0,
    // height: "100%",
    padding: "0px !important",
  },
  leftContent: {
    // border: "1px solid red",
    // padding: "5px 10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // alignItems: "center",
  },
  rightContent: {
    padding: "8px 24px",
    background: colorPrimary,
    // height: "60px",
    borderRadius: "16px 0px 0px 0px",
    // boxShadow:
    //   "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
    // padding: '10px 25px',
    // gap: 30
    // border: "1px solid red",
  },
  selectWrapper: {
    // border: "1px solid red",
    display: "flex",
    alignItems: "center",
  },
  radioWrapper: {
    // border: "1px solid green",
  },
  multipleStyle: {
    borderRadius: 8,
  },
  radioStyles: {
    "& .ant-radio-button-wrapper": {
      borderRadius: "0px !important",
      // background: "white !important",
      // width: ({ radioLayout }) => radioLayout.width,
      marginRight: 8,
      color: colorPrimary,
      fontWeight: 600,
      textAlign: "center",
      // letterSpacing: 2,
      fontSize: ({ fontSize }) => fontSize.xs,
      // height: ({ radioLayout }) => radioLayout.height,
    },
    "& .ant-radio-button-checked": {
      background: `#C0C0C0 !important`,
    },
  },
});

const yearOptions = [
  { value: 2020, label: "2020" },
  { value: 2021, label: "2021" },
  { value: 2022, label: "2022" },
  { value: 2023, label: "2023" },
  { value: 2024, label: "2024" },
];

const monthOptions = [
  { value: 1, label: "Jan" },
  { value: 2, label: "Feb" },
  { value: 3, label: "Mar" },
  { value: 4, label: "Apr" },
  { value: 5, label: "May" },
  { value: 6, label: "Jun" },
  { value: 7, label: "Jul" },
  { value: 8, label: "Aug" },
  { value: 9, label: "Sep" },
  { value: 10, label: "Oct" },
  { value: 11, label: "Nov" },
  { value: 12, label: "Dec" },
];

const getSelectedAirport = (ids = "", dataSource = []) => {
  let result = [];
  if (!ids) return result;
  for (let iii = 0; iii < dataSource.length; iii++) {
    const { value } = dataSource[iii];
    if (value === ids) result.push(dataSource[iii]);
  }
  return result;
};

const isVisibleDate = (view) => {
  switch (view?.toLowerCase()) {
    case "performance":
      return false;
    case "airport":
      return false;

    default:
      return true;
  }
};

const colMonth = ({ view, selectedMonth }) => {
  if (view.toLowerCase() === "airport") {
    return 8;
  } else if (view.toLowerCase() === "performance") {
    return 12;
  } else {
    if (selectedMonth?.length === 1) return 3;
    return 8;
  }
};

function Filter({ state, onChange }) {
  const { airportAp1Cache, activePage } = useSelector((state) => state.apps);
  let { userdata } = useSelector((state) => state.auth);

  const [airports, setAirports] = useState([]);
  const [year, setYear] = useState([]);

  const [month, setMonth] = useState([]);
  const [route, setRoute] = useState("");
  const [direct, seDirect] = useState("");

  const fontSize = useFontSize();
  const classes = useStyles({ fontSize });

  const formatedOpt = formatedOptions(
    airportAp1Cache,
    ["code", "name"],
    "_id",
    "asc"
  );

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const [_, baseRoute, page] = location?.pathname?.split("/");
    if (!activePage) {
      dispatch(setActivePage(page));
    }
  }, [activePage, location]);

  useEffect(() => {
    if (userdata?.airport_id) {
      const selectedAirports = getSelectedAirport(
        userdata.airport_id,
        formatedOpt
      );
      setAirports(selectedAirports);
      dispatch(setFilterPage({ airports: [userdata?.airport_id] }));
    }
    if (userdata?.cabang) {
      const selectedAirports = getSelectedAirport(
        userdata.airport_id,
        formatedOpt
      );
      setAirports(selectedAirports);
      dispatch(setFilterPage({ airports: [userdata?.cabang] }));
    }
  }, [userdata]);

  const isElsePerformPage = useMemo(() => {
    return activePage?.toLowerCase() !== "performance";
  }, [activePage]);

  const disabledAirportSelect = useMemo(() => {
    if (userdata?.airport_id) return true;
    if (userdata?.cabang) return true;
    return false;
  }, [userdata]);

  useEffect(() => {
    const visible = isVisibleDate(activePage);
    if (!visible) {
      onChange("date", null);
    }
  }, [activePage]);

  return (
    <div style={{ position: "relative", height: "100%" }}>
      <Row
        style={{
          borderBottom: `${fontSize.xs} solid ${colorPrimary}`,
          //   height: "100%",
          background: "white",
          alignItems: "start",
          //   border: "1px solid red",
        }}
      >
        <Col span={12}>
          <div
            style={{
              display: "flex",
              gap: 8,
              alignItems: "center",
              padding: fontSize.sm,
              //   border: "1px solid red",
            }}
          >
            <Typography.Title
              style={{
                margin: 0,
                fontWeight: "bold",
                fontSize: fontSize.lg,
              }}
            >
              Aero Performance Dashboard
            </Typography.Title>
            <Divider
              type="vertical"
              style={{
                borderInlineStart: "1px solid #dadada",
                height: "2rem",
              }}
            />
            <Typography.Title
              style={{
                margin: 0,
                fontSize: fontSize.md,
                color: "red",
                textTransform: "capitalize",
              }}
            >
              {activePage}
            </Typography.Title>
          </div>
        </Col>
        <Col className={classes.rightContent} span={12}>
          <Row style={{ flexDirection: "end" }} gutter={12}>
            {/* {isElsePerformPage && (
              <Col
                span={6}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                }}
              >
                <div
                  style={{
                    paddingBottom: "px",
                  }}
                >
                  <Typography
                    style={{
                      lineHeight: 1,
                      color: "white",
                      fontSize: fontSize.sm,
                    }}
                  >
                    Airports
                  </Typography>
                </div>
                <div style={{ width: "100%", height: "100%" }}>
                  <MultiSelectComponent
                    disabled={disabledAirportSelect}
                    className={classes.multipleStyle}
                    options={formatedOpt}
                    selected={airports}
                    onChange={(val) => {
                      setAirports(val);
                      const selected =
                        val.length !== 0 ? val.map((item) => item.value) : [];
                      onChange("airports", selected);
                    }}
                  />
                </div>
              </Col>
            )} */}
            <Col
              span={isElsePerformPage ? 8 : 12}
              style={{
                // height: "50px",
                display: "flex",
                flexDirection: "column",
                gap: 5,
                // height: "100%",
                // position: "relative",
              }}
            >
              <div
                style={{
                  // borderBottom: "1px solid white",
                  paddingBottom: "px",
                }}
              >
                <Typography
                  style={{
                    lineHeight: 1,
                    color: "white",
                    fontSize: fontSize.sm,
                  }}
                >
                  Year
                </Typography>
              </div>
              <div style={{ width: "100%", height: "100%" }}>
                <MultiSelectComponent
                  hasSelectAll={false}
                  options={yearOptions}
                  selected={yearOptions.filter(
                    (item) => item.value == state.year
                  )}
                  onChange={(val) => {
                    console.log({ val });
                    setYear([...year, val]);
                    const selected = val[val?.length - 1];
                    onChange("year", selected?.value || state.year);
                    onChange("date", null);
                    onChange("months", []);
                  }}
                />
              </div>
            </Col>
            <Col
              span={8}
              style={{
                // height: "50px",
                display: "flex",
                flexDirection: "column",
                gap: 5,
                // height: "100%",
                // position: "relative",
              }}
            >
              <div
                style={{
                  // borderBottom: "1px solid white",
                  paddingBottom: "px",
                }}
              >
                <Typography
                  style={{
                    lineHeight: 1,
                    color: "white",
                    fontSize: fontSize.sm,
                  }}
                >
                  Month
                </Typography>
              </div>
              <div style={{ width: "100%", height: "100%" }}>
                <MultiSelectComponent
                  options={monthOptions}
                  selected={monthOptions.filter((item) =>
                    state?.months?.some((select) => item.value === select)
                  )}
                  onChange={(val) => {
                    setMonth(val);
                    const selected = val.map((item) => item.value);
                    if (selected?.length !== 1) {
                      onChange("date", null);
                    }
                    onChange("months", selected);
                  }}
                />
              </div>
            </Col>
            {/* {isVisibleDate(activePage) && state?.months?.length === 1 && (
              <Col
                span={isElsePerformPage ? 2 : 12}
                style={{
                  // height: "50px",
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                  // height: "100%",
                  // position: "relative",
                }}
              >
                <div
                  style={{
                    // borderBottom: "1px solid white",
                    paddingBottom: "px",
                  }}
                >
                  <Typography
                    style={{
                      lineHeight: 1,
                      color: "white",
                      fontSize: fontSize.sm,
                    }}
                  >
                    Date
                  </Typography>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <DatePicker
                    format={"DD"}
                    style={{ height: "100%" }}
                    defaultPickerValue={dayjs(
                      `${state.year}-${state.months[0] + 1}`
                    )}
                    value={!!state.date ? dayjs(state.date) : null}
                    onChange={(value) => {
                      const isThisMonth =
                        state?.months[0] === dayjs(value).month();
                      if (!value) {
                        return onChange("date", null);
                      } else if (!isThisMonth) {
                        return onChange("months", [dayjs(value).month()]);
                      } else {
                        return onChange(
                          "date",
                          dayjs(value).format("DD-MMM-YYYY")
                        );
                      }
                    }}
                  />
                </div>
              </Col>
            )} */}
            {isElsePerformPage && (
              <Fragment>
                {/* <Col
                  span={4}
                  style={{
                    // height: "50px",
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,

                    // position: "relative",
                    // height: "100%"
                  }}
                >
                  <div
                    style={{
                      // borderBottom: "1px solid white",
                      paddingBottom: "px",
                      // height: "100%"
                    }}
                  >
                    <Typography
                      style={{
                        lineHeight: 1,
                        color: "white",
                        fontSize: fontSize.sm,
                      }}
                    >
                      Direct
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: 8,
                      height: "100%",
                    }}
                  >
                    <Button
                      onClick={() => onChange("direction", "Inc")}
                      size="small"
                      style={{
                        flex: 1,
                        flexGrow: 1,
                        borderRadius: 8,
                        background:
                          state?.direction === "Inc" ? colorSecondary : "white",
                        color: state?.direction === "Inc" ? "white" : "black",
                        border: "none",
                        fontSize: fontSize.sm,
                        height: "100%",
                      }}
                    >
                      Inc
                    </Button>
                    <Button
                      onClick={() => onChange("direction", "Out")}
                      size="small"
                      style={{
                        flex: 1,
                        flexGrow: 1,
                        borderRadius: 8,
                        background:
                          state?.direction === "Out" ? colorSecondary : "white",
                        color: state?.direction === "Out" ? "white" : "black",
                        border: "none",
                        fontSize: fontSize.sm,
                        height: "100%",
                      }}
                    >
                      Out
                    </Button>
                  </div>
                </Col> */}
                <Col
                  span={8}
                  style={{
                    // height: "50px",
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      // borderBottom: "1px solid white",
                      paddingBottom: "px",
                    }}
                  >
                    <Typography
                      style={{
                        lineHeight: 1,
                        color: "white",
                        fontSize: fontSize.sm,
                      }}
                    >
                      Route
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: 8,
                      height: "100%",
                    }}
                  >
                    <Button
                      size="small"
                      onClick={() => onChange("route", "domestic")}
                      style={{
                        background:
                          state?.route === "domestic"
                            ? colorSecondary
                            : "white",
                        color: state?.route === "domestic" ? "white" : "black",
                        flex: 1,
                        flexGrow: 1,
                        borderRadius: 8,
                        border: "none",
                        fontSize: fontSize.sm,
                        height: "100%",
                      }}
                    >
                      Dom
                    </Button>
                    <Button
                      size="small"
                      onClick={() => onChange("route", "international")}
                      style={{
                        flex: 1,
                        flexGrow: 1,
                        background: "white",
                        borderRadius: 8,
                        background:
                          state?.route === "international"
                            ? colorSecondary
                            : "white",
                        color:
                          state?.route === "international" ? "white" : "black",
                        border: "none",
                        fontSize: fontSize.sm,
                        height: "100%",
                      }}
                    >
                      Int
                    </Button>
                  </div>
                </Col>
              </Fragment>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Filter;
