import React, { Fragment, useMemo, useState } from "react";
import MainChart from "../../../components/Chart/MainChart";
import { Spin, Typography } from "antd";
import { useFontSize } from "../../../hooks/useDimension";
import formatNumberToMillions from "../../../helper/numberSparator";
import numberSparator from "../../../helper/numberSparator";

const PPMSByRouteChart = ({ title, dataSource, loading }) => {
  console.log({ dataSource });
  const fontSize = useFontSize();
  const payingPaxData = useMemo(() => {
    const sortingData = dataSource?.data
      .map((item) => {
        return {
          value: item.pax.adult + item.pax.child + item.pax.infant,
          name: item.code,
          fullName: item.name,
        };
      })
      .slice(0, 9)
      .sort((a, b) => b.value - a.value);
    return sortingData || [];
  }, [dataSource]);

  const othersData = useMemo(() => {
    let result = [];
    const total = dataSource?.data
      .slice(9)
      .reduce(
        (acc, cur) => acc + cur.pax.adult + cur.pax.child + cur.pax.infant,
        0
      );
    result = [
      { value: total, name: "Others", fullName: "Total of other routes" },
    ];
    return result;
  }, [dataSource]);

  const max = useMemo(() => {
    return !!payingPaxData && payingPaxData[0]?.value + 100;
  }, [payingPaxData, dataSource]);

  const totalPayingPax = useMemo(() => {
    return payingPaxData?.reduce(function (acc, current) {
      return acc + current.value;
    }, 0);
  }, [payingPaxData, dataSource]);

  const finalData = useMemo(() => {
    return [...payingPaxData, ...othersData];
  }, [dataSource]);

  const bluePalette = ["#4285F4", "#34A853", "#FBBC05", "#EA4335", "#0F9D58"];

  // Generate a random blue color for the rest of the data
  const getRandomBlueColor = () => {
    const randomIndex = Math.floor(bluePalette.length);
    return bluePalette[randomIndex];
  };

  const predefinedColors = ["#FFD700", "#C0C0C0", "#CD7F32"];

  const option = {
    tooltip: {
      trigger: "item",
      formatter: (format) =>
        `<div>
          <div style=\"display:flex;align-items:center;line-height:5px\">${
            format.marker
          }<p>${format.data.name}</p>
          </div>
          <p>(${format.data.fullName})
          </p>
          <p style=\"font-weight:600\">${numberSparator(format.data.value)}
          </p>
        </div>`,
    },
    visualMap: {
      show: false,
      min: 0,
      max: max,
      inRange: {},
      // color: ["#bf444c", "#d88273", "#f6efa6"],
    },
    legend: {
      type: "scroll",
      orient: "vertical",
      right: "5%",
      top: "15%",
      // height: "410px",
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: "90%",
        itemStyle: {
          borderColor: "#fff",
          borderWidth: 2,
          // color: "#014f97",
        },
        label: {
          show: true,
          position: "outside",
          formatter: "{b} : {d}%",
          textStyle: {
            fontSize: "12px",
          },
        },
        center: ["50%", "50%"],
        data: finalData?.map((item, idx) => ({
          value: item.value,
          name: item.name,
          fullName: item.fullName,
          itemStyle: {
            color: idx < 3 ? predefinedColors[idx] : getRandomBlueColor(),
          },
        })),
        emphasis: {
          itemStyle: {},
        },
      },
    ],
  };

  return (
    <MainChart
      title={
        <Fragment>
          {title} {loading && <Spin />}
        </Fragment>
      }
      height={fontSize.md * 13}
      option={option}
      otherComponent={() => (
        <div
          style={{
            // border: "1px solid red",
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <Typography style={{ fontWeight: "bold", fontSize: "20px" }}>
              {!totalPayingPax
                ? "Loading . . ."
                : formatNumberToMillions(totalPayingPax)}
            </Typography>
            <Typography>Paying Pax</Typography>
          </div>
          <div style={{ textAlign: "center" }}>
            <Typography style={{ fontWeight: "bold", fontSize: "20px" }}>
              {dataSource?.routes}
            </Typography>
            <Typography>Routes</Typography>
          </div>
        </div>
      )}
    />
  );
};

export default PPMSByRouteChart;
