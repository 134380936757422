import React, { Fragment } from "react";
import Image from "../../../../assets/images/LandingIMG.png";
import { Typography } from "antd";
import Config from "../../../../config/env";
import Bubble from "./Bubble";

const { colorPrimary, colorSecondary } = Config;

const index = () => {
  return (
    <Fragment>
      <div
        style={{
          //   border: "1px solid red",
          height: "10vh",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 100px 0px",
        }}
      >
        <div>
          <Typography
            style={{
              fontSize: "55px",
              textAlign: "center",
              lineHeight: "20px",
              color: colorSecondary,
              fontWeight: "bold",
              //   border: "1px solid",
            }}
          >
            ESI <br />
            <span style={{ fontSize: "15px" }}>Enterprise</span>
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            // border: "1px solid pink",
            gap: 100,
          }}
        >
          <a
            style={{
              fontWeight: "bold",
              textDecoration: "none",
              color: colorSecondary,
              fontSize: "20px",
            }}
            href="/dashboard/overview"
          >
            Dashboard
          </a>
          <a
            style={{
              fontWeight: "bold",
              textDecoration: "none",
              fontSize: "20px",
              color: colorSecondary,
            }}
            href="/"
          >
            About
          </a>
          <a
            style={{
              fontWeight: "bold",
              textDecoration: "none",
              fontSize: "20px",
              color: colorSecondary,
            }}
            href="/"
          >
            Contact
          </a>
          <a
            style={{
              fontWeight: "bold",
              textDecoration: "none",
              fontSize: "20px",
              color: colorSecondary,
            }}
            href="/"
          >
            Profile
          </a>
        </div>
      </div>

      <div style={{ display: "flex", height: "80vh", background: "#fefefe" }}>
        <div
          style={{
            width: "40%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "45px",
              color: colorPrimary,
            }}
          >
            Aero Perfomance Dashboard
          </Typography>
        </div>
        <div
          style={{
            width: "60%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <Bubble />
          <img src={Image} style={{ width: "60%", position: "relative" }} />
          <Typography
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              color: colorPrimary,
            }}
          >
            Edifly Solusi Indonesia ©
          </Typography>
        </div>
      </div>
    </Fragment>
  );
};

export default index;
