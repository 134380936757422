import React, { Fragment } from "react";

const Bubble = () => {
  return (
    <Fragment>
      <div
        style={{
          width: "200px",
          height: "200px",
          position: "absolute",
          background: "#817dd9",
          borderRadius: "100%",
          right: 10,
          opacity: 0.5,
          top: 10,
        }}
      ></div>
      <div
        style={{
          width: "100px",
          height: "100px",
          position: "absolute",
          background: "#817dd9",
          borderRadius: "100%",
          right: 30,
          opacity: 0.5,
          top: 150,
        }}
      ></div>
      <div
        style={{
          width: "300px",
          height: "300px",
          position: "absolute",
          background: "#817dd9",
          borderRadius: "100%",
          left: 10,
          opacity: 0.5,
          bottom: 10,
        }}
      ></div>
      <div
        style={{
          width: "300px",
          height: "300px",
          position: "absolute",
          background: "#817dd9",
          borderRadius: "100%",
          // left: 10,
          opacity: 0.5,
          bottom: 450,
        }}
      ></div>
      <div
        style={{
          width: "200px",
          height: "200px",
          position: "absolute",
          background: "#817dd9",
          borderRadius: "100%",
          // left: 10,
          opacity: 0.5,
          bottom: 250,
        }}
      ></div>
      <div
        style={{
          width: "100px",
          height: "100px",
          position: "absolute",
          background: "#817dd9",
          borderRadius: "100%",
          left: 10,
          opacity: 0.5,
          bottom: 10,
        }}
      ></div>
      <div
        style={{
          width: "100px",
          height: "100px",
          position: "absolute",
          background: "#817dd9",
          borderRadius: "100%",
          left: 200,
          opacity: 0.5,
          bottom: 300,
        }}
      ></div>
      <div
        style={{
          width: "150px",
          height: "150px",
          position: "absolute",
          background: "#817dd9",
          borderRadius: "100%",
          right: 10,
          opacity: 0.5,
          bottom: 10,
        }}
      ></div>
      <div
        style={{
          width: "50px",
          height: "50px",
          position: "absolute",
          background: "#817dd9",
          borderRadius: "100%",
          right: 110,
          opacity: 0.5,
          bottom: 110,
        }}
      ></div>
      <div
        style={{
          width: "300px",
          height: "300px",
          position: "absolute",
          background: "#817dd9",
          borderRadius: "100%",
          right: 10,
          opacity: 0.5,
          bottom: 10,
        }}
      ></div>
    </Fragment>
  );
};

export default Bubble;
