import { Col, Row } from "antd";
import React from "react";
import SummarySection from "../../components/SummarySection";
import TrendChart from "../../components/TrendChart";
import RCbyBussinesLine from "../../components/RCbyBussinesLine";
import RCbyAirlines from "../../components/RCbyAirlines";
import { useDashboardOverview } from "../../../../hooks/useDashboardV2";
import { useSelector } from "react-redux";
import NotFoundData from "../../../../components/NotFoundData";

const Overview = () => {
  const { filterPage: filterValue } = useSelector((state) => state.apps);
  const [data, loading] = useDashboardOverview({
    filter: filterValue,
    view: "analysis",
  });

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: 8,
        // background: "salmon",
      }}
    >
      <div id="card" style={{ flex: 1.3 }}>
        <SummarySection
          loading={loading}
          data={data?.productionTrendAnalysis}
        />
      </div>
      <div id="trend" style={{ flex: 1.7 }}>
        <Row gutter={8} style={{ height: "100%" }}>
          <Col lg={{ span: 24 }} style={{ height: "100%" }}>
            <div style={{}}>
              {data?.productionTrendAnalysis?.length === 0 ? (
                <NotFoundData />
              ) : (
                <TrendChart
                  loading={loading}
                  dataSource={data?.productionTrendAnalysis}
                  title={"Production Trend Analysis"}
                />
              )}
            </div>
          </Col>
          {/* <Col lg={{ span: 12 }} style={{ height: "100%" }}>
            <div style={{}}>
              <TrendChart title={"Revenue Trend Analysis"} />
            </div>
          </Col> */}
        </Row>
      </div>
      {/* <div id="last" style={{ flex: 2.2 }}>
        <Row gutter={8} style={{ height: "100%" }}>
          <Col lg={{ span: 6 }} style={{ height: "100%" }}>
            <div style={{}}>
              <RCbyBussinesLine
                title={"Revenue Contribution by Bussiness Line"}
              />
            </div>
          </Col>
          <Col lg={{ span: 6 }} style={{ height: "100%" }}>
            <div style={{}}>
              <RCbyAirlines
                title={"Revenue Contribution br Airlines (In Million IDR)"}
              />
            </div>
          </Col>
          <Col lg={{ span: 12 }} style={{ height: "100%" }}>
            <div style={{}}></div>
          </Col>
        </Row>
      </div> */}
    </div>
  );
};

export default Overview;
