import React, { Fragment, useMemo } from "react";
import MainChart from "../../../components/Chart/MainChart";
import formatNumberToMillions from "../../../helper/formatNumberToM";
import numberSparator from "../../../helper/numberSparator";
import { Spin } from "antd";
import useWindowDimensions, { useFontSize } from "../../../hooks/useDimension";

const Top10NIP = ({ title, loading, dataSource, route }) => {
  const fontSize = useFontSize();
  const { width } = useWindowDimensions();
  const dataTop10 = useMemo(() => {
    const sortData = dataSource?.map((item) => {
      const total = item?.pax.adult + item?.pax.child + item?.pax.infant;
      return {
        value: total,
        country:
          route === "" && item?.country === "ID"
            ? item?.city
            : route === "" && item?.country !== "ID"
            ? item?.country_name
            : route === "international"
            ? item?.country_name
            : item?.city,
      };
    });
    return sortData?.slice(0, 10).sort((a, b) => a.value - b.value);
  }, [dataSource]);

  // Define a blue palette
  const bluePalette = ["#4285F4", "#34A853", "#FBBC05", "#EA4335", "#0F9D58"];

  // Generate a random blue color for the rest of the data
  const getRandomBlueColor = () => {
    const randomIndex = Math.floor(Math.random() * bluePalette.length);
    return bluePalette[randomIndex];
  };

  console.log({
    route,
  });

  const predefinedColors = ["#CD7F32", "#C0C0C0", "#FFD700"];

  const option = {
    grid: {
      x: fontSize.xl * 5,
      x2: fontSize.xl * 4,
      y: fontSize.xs,
      y2: fontSize.lg,
    },
    // dataset,
    xAxis: {
      show: false,
    },
    yAxis: {
      type: "category",
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      data:
        dataTop10?.map((item) =>
          item.country === null ? "-" : item.country
        ) || [],
    },
    series: [
      {
        type: "bar",
        data: dataTop10?.map((item, idx) => ({
          value: item.value,
          itemStyle: {
            color: idx > 6 ? predefinedColors[idx - 7] : getRandomBlueColor(),
          },
        })),
        label: {
          show: true,
          position: "right",
          formatter: (format) =>
            `${
              format?.data.value < 999999
                ? numberSparator(format?.data.value)
                : formatNumberToMillions(format?.data.value)
            }`,
        },
        // color: "#01488a",
      },
    ],
  };
  return (
    <MainChart
      title={
        <Fragment>
          {title} {loading && <Spin />}
        </Fragment>
      }
      height={width >= 1920 ? fontSize.md * 21 : fontSize.md * 16}
      option={option}
    />
  );
};

export default Top10NIP;
