import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import MemberPages from "./pages";

const RouterPage = ({ userdata, isMobileView }) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          !!userdata ? (
            <Navigate to={"/dashboard/overview"} />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/login"
        element={
          !!userdata ? (
            <Navigate to={"/dashboard/overview"} />
          ) : (
            <div>Login Page</div>
          )
        }
      />
      <Route
        path="/*"
        element={
          !!userdata ? (
            <MemberPages isMobileView={isMobileView} />
          ) : (
            <Navigate to={"/"} />
          )
        }
      />
    </Routes>
  );
};

const Dashboard = () => {
  const userdata = true;
  const isMobileView = false;
  return <RouterPage userdata={userdata} isMobileView={isMobileView} />;
};

export default Dashboard;
