import { Spin, Typography } from "antd";
import MainCard from "../../../components/MainCard";
import { useFontSize } from "../../../hooks/useDimension";
import React, { Fragment, useMemo } from "react";
import numberSparator from "../../../helper/numberSparator";
import moment from "moment";

const SummaryCard = ({
  bgColor,
  loading,
  total,
  isRealCurrent = false,
  prevYear,
  isRev = "Prod",
}) => {
  const fontSize = useFontSize();
  const realPrev = useMemo(() => {
    return (((total?.growth / total.current) * 100) / 100).toFixed(2);
  }, [loading]);

  const prodRender = () => {
    return (
      <Fragment>
        <Typography
          style={{
            fontSize: fontSize.xl,
            fontWeight: "bold",
            alignSelf: "center",
            color: isRealCurrent
              ? "white"
              : total?.growth > total?.current
              ? "red"
              : "green",
          }}
        >
          {!!isRealCurrent ? (
            <span>
              Real {moment().format("YYYY")}
              <br />
              {numberSparator(total?.current)}
            </span>
          ) : (
            `${realPrev}%`
          )}
        </Typography>
      </Fragment>
    );
  };

  // console.log({ total });
  const renderContent = () => {
    return (
      <Fragment>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            background: isRealCurrent ? "none" : "white",
            borderTopLeftRadius: fontSize.xs,
            padding: fontSize.xs - 2,
            borderTopRightRadius: fontSize.xs,
            borderBottomLeftRadius: fontSize.sm,
            borderBottomRightRadius: fontSize.sm,
            flexGrow: 1,
            textAlign: "center",
            justifyContent: "center",
            // alignItems: "center"
          }}
        >
          {!isRealCurrent && (
            <div style={{ textAlign: "start" }}>
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  lineHeight: 0.5,
                }}
              >
                Real {prevYear}
              </Typography>
              <Typography style={{ fontWeight: "bold" }}>
                {numberSparator(total.growth)}
              </Typography>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            {!!loading ? (
              <div style={{ margin: 10 }}>
                <Spin size="large" />
              </div>
            ) : (
              prodRender()
            )}
          </div>
        </div>
        {!isRealCurrent && (
          <div
            style={{
              textAlign: "center",
              padding: "0.3rem 1rem",
              fontWeight: "bold",
            }}
          >
            <Typography style={{ fontSize: fontSize.sm, color: "white" }}>
              {isRev}. Growth Rate
            </Typography>
          </div>
        )}
      </Fragment>
    );
  };
  return (
    <MainCard>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          background: !!bgColor ? bgColor : "#dadada",
          justifyContent: "space-between",
          height: "100%",
          borderTopLeftRadius: fontSize.xs + 2,
          borderTopRightRadius: fontSize.xs + 2,
          borderBottomLeftRadius: fontSize.xs,
          borderBottomRightRadius: fontSize.xs,
        }}
      >
        {renderContent()}
      </div>
    </MainCard>
  );
};

export default SummaryCard;
