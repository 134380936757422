import Landing from "./pages/landing";
import Overview from "./pages/overview";
import Stakeholders from "./pages/stakeholders";

export const Routing = (isMobileView) => [
  {
    to: "/overview",
    level: ["cabang"],
    component: !isMobileView ? <Overview /> : <div>Mobile Overview</div>,
  },
  {
    to: "/stakeholders",
    level: ["cabang"],
    component: !isMobileView ? (
      <Stakeholders />
    ) : (
      <div>Mobile Stakeholders</div>
    ),
  },
];
