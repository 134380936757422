import React, { Fragment, useMemo } from "react";
import MainChart from "../../../components/Chart/MainChart";
import { Spin, Typography } from "antd";
import useWindowDimensions, { useFontSize } from "../../../hooks/useDimension";

const PPMSByAirlineChart = ({ dataSource, loading, title }) => {
  // const loading = false;
  const fontSize = useFontSize();
  const { width } = useWindowDimensions();

  const payingPaxData = useMemo(() => {
    const mergedData = dataSource?.map(function (item) {
      const totalArrivalPax =
        item?.pax.arr.adult + item?.pax.arr.child + item?.pax.arr.infant;
      const totalDeparturePax =
        item?.pax.dep.adult + item?.pax.dep.child + item?.pax.dep.infant;

      return {
        value: totalArrivalPax + totalDeparturePax,
        name: item.name,
      };
    });

    return {
      airlinesPax:
        mergedData?.slice(0, 9).sort((a, b) => b.value - a.value) || [],
      airlines: mergedData?.length,
    };
  }, [dataSource]);

  const othersData = useMemo(() => {
    let result = 0;
    const total = dataSource?.slice(9).reduce((acc, item) => {
      Object.keys(item.pax.dep).forEach((key) => {
        acc[key] = (acc[key] || 0) + item.pax.dep[key] + item.pax.arr[key];
      });
      return acc;
    }, {});
    result =
      total?.adult +
      total?.child +
      total?.crew +
      total?.infant +
      total?.transfer +
      total?.transit;
    return [{ value: result, name: "Others" }] || [];
  }, [dataSource]);

  const max = useMemo(() => {
    return !!payingPaxData.airlinesPax && payingPaxData.airlinesPax[0]?.value;
  }, [payingPaxData]);

  const finalData = useMemo(() => {
    return [...payingPaxData.airlinesPax, ...othersData];
  }, [dataSource]);

  const bluePalette = ["#4285F4", "#34A853", "#FBBC05", "#EA4335", "#0F9D58"];

  // Generate a random blue color for the rest of the data
  const getRandomBlueColor = () => {
    const randomIndex = Math.floor(bluePalette.length);
    return bluePalette[randomIndex];
  };

  const predefinedColors = ["#FFD700", "#C0C0C0", "#CD7F32"];

  const option = {
    title: {},
    tooltip: {
      trigger: "item",
    },
    visualMap: {
      show: false,
      min: 0,
      max: max,
      inRange: {
        // colorLightness: [1, 0],
        // color: ["#BBD7EF", "#3371AB", "#004893"],
      },
    },
    legend: {
      icon: "pin",
      width: "30px",
      type: "scroll",
      orient: "vertical",
      right: width < 1920 ? "0%" : "5%",
      top: "20%",
      textStyle: {
        fontSize: "10px",
      },
      formatter: (format) =>
        format.length > 20 ? `${format.slice(0, 7)} ...` : format,
    },
    label: {
      show: false,
    },
    emphasis: {
      label: {
        show: true,
      },
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: width >= 1920 ? "80%" : "70%",
        center: ["45%", "50%"],
        itemStyle: {
          borderColor: "#fff",
          borderWidth: 1.5,
          // color: "#014f97",
        },

        label: {
          show: true,
          position: "outside",
          formatter: "{d}%",
          // color: "white",
          textStyle: {
            fontSize: "10px",
          },
        },
        data: finalData?.map((item, idx) => ({
          value: item.value,
          name: item.name,
          // fullName: item.fullName,
          itemStyle: {
            color: idx < 3 ? predefinedColors[idx] : getRandomBlueColor(),
          },
        })),
        emphasis: {
          itemStyle: {
            // shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  console.log({ width });

  return (
    <MainChart
      title={
        <Fragment>
          {title} {loading && <Spin />}
        </Fragment>
      }
      height={width >= 1920 ? fontSize.md * 18 : fontSize.md * 12}
      option={option}
      otherComponent={() => (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <Typography style={{ fontWeight: "bold", fontSize: fontSize.md }}>
              {payingPaxData.airlines}
            </Typography>
            <Typography>Airlines</Typography>
          </div>
        </div>
      )}
    />
  );
};

export default PPMSByAirlineChart;
