import React from "react";
import { createUseStyles } from "react-jss";
import { useFontSize } from "../hooks/useDimension";

const useStyles = createUseStyles({
  cardContainer: {
    // border: '1px solid',
    padding: ({ isMobile }) => (isMobile ? "8px 0px 24px 0px" : 0),
    borderRadius: ({ fontSize }) => fontSize.xs,
    background: ({ isNonBackground }) => (isNonBackground ? "" : "white"),
    height: "100%",
    boxShadow: ({ isNonBackground }) =>
      isNonBackground
        ? ""
        : "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
    boxSizing: "border-box",
  },
});

export default function MainCard({
  children,
  isMobile,
  className,
  isNonBackground,
  style = {},
}) {
  const fontSize = useFontSize();
  const classes = useStyles({ fontSize, isMobile, isNonBackground });
  return (
    <div
      className={`main-card ${classes.cardContainer} ${className}`}
      style={style}
    >
      {children}
    </div>
  );
}
