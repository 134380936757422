import { Col, Row } from "antd";
import React, { useMemo } from "react";
import SummaryCard from "./SummaryCard";
import Config from "../../../config/env";
import { useDashboardOverview } from "../../../hooks/useDashboardV2";
import moment from "moment";
import { useSelector } from "react-redux";
const { colorPrimary, colorSecondary } = Config;

const SummarySection = ({ data, loading }) => {
  const { filterPage: filterValue } = useSelector((state) => state.apps);
  const selectedYearMoment = moment({ year: Number(filterValue?.year) });
  const previousYearMoment = selectedYearMoment.subtract(1, "year");
  const [growthData, loadingGrowth] = useDashboardOverview({
    filter: { year: previousYearMoment.format("YYYY") },
  });

  const total = useMemo(() => {
    const growth = growthData?.productionTrendAnalysis?.reduce((acc, item) => {
      Object.keys(item.pax.dep).forEach((key) => {
        acc[key] = (acc[key] || 0) + item.pax.dep[key] + item.pax.arr[key];
      });
      return acc;
    }, {});
    const current = data?.reduce((acc, item) => {
      Object.keys(item.pax.dep).forEach((key) => {
        acc[key] = (acc[key] || 0) + item.pax.dep[key] + item.pax.arr[key];
      });
      return acc;
    }, {});
    return {
      growth:
        growth?.adult +
        growth?.child +
        growth?.infant +
        growth?.transit +
        growth?.transfer +
        growth?.crew,
      current:
        current?.adult +
        current?.child +
        current?.infant +
        current?.transit +
        current?.transfer +
        current?.crew,
    };
  }, [growthData, data]);

  return (
    <Row
      gutter={8}
      style={{
        height: "100%",
      }}
    >
      {[...Array(2)].map((item, idx) => {
        return (
          <Col
            lg={{ span: 12 }}
            xs={{ span: 12 }}
            style={{ height: "100%" }}
            key={idx}
          >
            <div style={{ height: "100%" }}>
              <SummaryCard
                isRev={idx === 1 ? "Prod" : "Rev"}
                prevYear={previousYearMoment.format("YYYY")}
                isRealCurrent={idx === 0 || idx === 2}
                total={total}
                loading={loading}
                bgColor={idx < 2 ? colorSecondary : colorPrimary}
              />
            </div>
          </Col>
        );
      })}
    </Row>
  );
};

export default SummarySection;
