// import { useEffect, useMemo, useState } from "react";
import { useMemo } from "react";
import { useFetch } from "./useFetch";
// import moment from "moment";

export const useDashboardOverview = ({
  route,
  year,
  month,
  doSend = true,
  view,
  ...rest
}) => {
  const createDynamicQueryString = (obj) => {
    let objFilter = {
      year: obj.year,
    };
    if (obj.route !== "") {
      objFilter = {
        ...objFilter,
        route: obj.route,
      };
    }
    if (obj.months?.length > 0) {
      objFilter = {
        ...objFilter,
        months: obj.months,
      };
    }
    const queryParams = Object.entries(objFilter)
      .filter(([key, value]) => value !== undefined)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return queryParams;
  };

  const url = useMemo(() => {
    return `/${view || "analysis"}?${createDynamicQueryString(rest.filter)}`;
  }, [rest]);

  const [data, loading] = useFetch(url, true, doSend);
  return [data, loading];
};
