export default function formatNumberToMillions(number) {
  if (isNaN(number)) {
    return "Invalid number";
  }

  if (number < 1000000) {
    return number.toString(); // Return the number as it is if it's less than a million
  }

  const formattedNumber = (number / 1000000).toFixed(2) + "M";
  return formattedNumber;
}
