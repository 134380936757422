import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MainCard from "../MainCard";
import { Select, Col, Row, Typography } from "antd";
import ReactECharts from "echarts-for-react";
import ModalChart from "../../components/Modal/ModalChart";
import Config from "../../config/env";
import { useFontSize } from "../../hooks/useDimension";
import { useSelector } from "react-redux";
import { useDashboardOverview } from "../../hooks/useDashboardV2";
// import FiltersSwapper from "./FiltersSwapper";

const { colorPrimary } = Config;

const Div = ({ ...props }) => {
  return <div {...props}></div>;
};

export default function MainChart({
  title,
  className,
  subTitle,
  option,
  height,
  onChange,
  filterComp = [],
  fullscreen = false,
  onFullScreen,
  otherComponent,
  onClickItemChart,
  withOutCard,
  defaultValue,
  isPaddingBottom = false,
  style,
}) {
  const fontSize = useFontSize();
  const [open, setOpen] = useState(false);
  const [doRenderModal, setDoRenderModal] = useState(false);
  useEffect(() => {
    !!onFullScreen && onFullScreen(open);
  }, [open]);

  const Component = !!withOutCard ? Div : MainCard;

  const opt = useMemo(() => {
    let result = {
      ...option,
      textStyle: {
        ...option.textStyle,
        fontFamily: "Inter",
        fontSize: fontSize.sm,
      },
    };
    if (!!option.yAxis) {
      result = {
        ...result,
        yAxis: {
          ...result.yAxis,
          axisLabel: {
            ...result.yAxis?.axisLabel,
            fontSize: fontSize.sm,
          },
        },
      };
    }

    if (!!option.xAxis) {
      result = {
        ...result,
        xAxis: {
          ...result.xAxis,
          axisLabel: {
            ...result.xAxis?.axisLabel,
            fontSize: fontSize.sm,
          },
        },
      };
    }
    return result;
  }, [option]);

  useEffect(() => {
    if (!open) {
      setDoRenderModal(false);
    } else {
      setTimeout(() => {
        setDoRenderModal(true);
      }, 500);
    }
  }, [open]);
  const callbackClick = useCallback(
    (val) => {
      typeof onClickItemChart === "function" && onClickItemChart(val);
    },
    [onClickItemChart]
  );
  const renderBody = (isModal, t) => {
    const h = isModal ? "90%" : t;
    return (
      <Fragment>
        <Row
          style={{
            justifyContent: "space-between",
            paddingTop: !!filterComp ? 8 : 4,
            paddingLeft: 16,
            paddingRight: 16,
          }}
          gutter={[16]}
        >
          <Col>
            <Typography
              className="title-onDoubleClick"
              style={{
                fontWeight: "bold",
                cursor: !!fullscreen && "pointer",
                fontSize: fontSize.md,
                color: colorPrimary,
              }}
            >
              {title}
            </Typography>
            {!!subTitle && typeof subTitle === "string" && (
              <Typography
                style={{
                  fontSize: fontSize.sm,
                  color: "gray",
                }}
              >
                <em>{subTitle}</em>
              </Typography>
            )}
          </Col>
          {!!filterComp && filterComp.length !== 0 && (
            <Col>
              <Select
                defaultValue={defaultValue}
                style={{ width: 120 }}
                onChange={onChange}
                options={filterComp}
              />
            </Col>
          )}
        </Row>
        <div
          style={{
            flexGrow: 1,
            position: "relative",
            // border: "1px solid red",
            paddingBottom: isPaddingBottom && 16,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <ReactECharts
            style={{
              height: h,
              width: "100%",
              padding: 8,
              paddingBottom: 0,
            }}
            onEvents={{
              click: callbackClick,
            }}
            option={opt}
          />
          {!!otherComponent && typeof otherComponent === "function" && (
            <div style={{ paddingBottom: 20 }}>{otherComponent()}</div>
          )}
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Component style={style} className={className}>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
          onDoubleClick={(e) => fullscreen && setOpen(true)}
        >
          {renderBody(false, height)}
        </div>
        <ModalChart open={open} setOpen={setOpen}>
          {doRenderModal && renderBody(true, height)}
        </ModalChart>
      </Component>
    </Fragment>
  );
}
