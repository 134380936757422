import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {GetAPI, PostAPI} from './apiHandling'
import jwtDecode from 'jwt-decode'
import {toast} from 'react-toastify'
import {levelToRoles,  levels} from './levelConvert'
const authSlice = createSlice({
    name:'auth',
    initialState:{userdata:false, token:'', apps:'', initComplete:false, url:'', workplace:''},
    reducers:{
        successLogin(state, action) {
            const {userdata} = action.payload;            
            // const level=(state.apps==='head-office')&&levels;
            const levelStr = levelToRoles(userdata.level || 0);            
            state.userdata = {...userdata, levelStr};
            state.token = action.payload.token
        },
        setApps(state, action) {
            state.apps = action.payload
            switch (action.payload) {
                case 'airline':
                    state.url='airline/';
                    break;
            
                default:
                    state.url='';
                    break;
            }
        },
        doLogout(state) {
            state.userdata = false
            state.token = ''
            window.localStorage.removeItem(state.apps)
        },
        initComplete(state) {
            state.initComplete = true
        },
        setWorkplace(state, action){
            state.workplace=action.payload;
        }
    }
})

export const {successLogin, setApps, doLogout, initComplete, setWorkplace} = authSlice.actions

const checkWorkplace=(userdata)=>{
    if(!!userdata?.cabang){
        return 'cabang';
    }
    else if(!!userdata?.jaster_id?._id){
        return 'jaster';
    }
    else if(!!userdata?.empu_id?._id){
        return 'jpt';
    }
    return 'head-office';
}

export const login = createAsyncThunk('auth/login', async({user, password, app, ...others}, thunkAPI) => {
    const {dispatch, getState} = thunkAPI
    const {auth:{apps, url:uri}} = getState()
    const tokenName = `${apps}`
    const url=uri+'auth/login?app='+app;
    const token = await dispatch(PostAPI({url, data:{username:user, password, app, ...others}}))
    if (!!token.payload) {
        const userdata = jwtDecode(token.payload)
        window.localStorage.setItem(tokenName, token.payload)
        dispatch(successLogin({token:token.payload, userdata}))
        toast.success(`Welcome to Apps ${userdata?.name}`)
        const workplace=checkWorkplace(userdata);
        dispatch(setWorkplace(workplace));
    }
})
export const logout = createAsyncThunk('auth/logout', async (body, thunkAPI) => {
    const {dispatch} = thunkAPI
    // const {auth:{apps, url:uri}} = getState()
    // const url=uri+'auth/logout';
    // await dispatch(GetAPI({url}));
    // const tokenName = `${apps}`
    // window.localStorage.removeItem(tokenName)
    dispatch(doLogout())
})
export const initMe = createAsyncThunk('auth/me', async (body, thunkAPI) => {
   //dipslay masih splash
    const {dispatch, getState} = thunkAPI
    const {auth:{apps, url:uri}} = getState()
    const tokenName = `${apps}`
    const token = window.localStorage.getItem(tokenName)
    if (!!token) {
        const userdata = jwtDecode(token)
        dispatch(successLogin({userdata, token}))
        const url=uri+'auth/me';
        await dispatch(GetAPI({url}))
        if (userdata){
            dispatch(successLogin({userdata, token}))
            const workplace=checkWorkplace(userdata);
            dispatch(setWorkplace(workplace));
        }
    }
    dispatch(initComplete())
})
// export const login
export default authSlice.reducer