import { Col, Row, Typography, Input, Spin } from "antd";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import useWindowsDimension, { useFontSize } from "../../../hooks/useDimension";
import Config from "../../../config/env";
import MainCard from "../../../components/MainCard";
import { createUseStyles } from "react-jss";
import SearchComponent from "./SearchComponent";

const { colorPrimary } = Config;
const useStyles = createUseStyles({
  customInput: {
    "&::placeholder": {
      fontWeight: "bold !important",
      // Add other placeholder styles as needed
    },
  },
  LoadFactorStyle: {
    "&:hover": {
      background: `#4a9b00 !important`,
    },
  },
});

const LoadFactor = ({ fullscreen = false, title, dataSource, loading }) => {
  const fontSize = useFontSize();
  const classes = useStyles();
  const { width } = useWindowsDimension();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermAirlines, setSearchTermAirlines] = useState("");
  const [searchData, setSearchData] = useState(null);
  const [searchDataAirlines, setSearchDataAirlines] = useState(null);
  const [mapData, setMapData] = useState(null);
  const [mapDataAirlines, setMapDataAirlines] = useState(null);

  const totalRoutesPax = useMemo(() => {
    return dataSource?.routes?.data.reduce(function (acc, current) {
      return acc + current.pax.adult + current.pax.child + current.pax.infant;
    }, 0);
  }, [dataSource]);

  const totalAirlinesPax = useMemo(() => {
    const totalDep = dataSource?.airlines?.reduce(function (acc, current) {
      return (
        acc +
        current?.pax?.dep.adult +
        current?.pax?.dep.child +
        current?.pax?.dep.infant
      );
    }, 0);
    const totalArr = dataSource?.airlines?.reduce(function (acc, current) {
      return (
        acc +
        current?.pax?.arr.adult +
        current?.pax?.arr.child +
        current?.pax?.arr.infant
      );
    }, 0);
    return totalArr + totalDep;
  }, [dataSource]);

  const tempDataRoutes = useMemo(() => {
    const sortingData = dataSource?.routes?.data.map((item) => {
      return {
        value: (
          ((item.pax.adult + item.pax.child + item.pax.infant) /
            totalRoutesPax) *
          100
        ).toFixed(2),
        label: item.code,
      };
    });
    return sortingData?.sort((a, b) => b.value - a.value);
  }, [dataSource]);

  const tempDataAirlines = useMemo(() => {
    const mergedData = dataSource?.airlines?.map(function (item) {
      const totalArrivalPax =
        item?.pax?.arr.adult + item?.pax?.arr.child + item?.pax?.arr.infant;
      const totalDeparturePax =
        item?.pax?.dep.adult + item?.pax?.dep.child + item?.pax?.dep.infant;

      return {
        value: (
          ((totalArrivalPax + totalDeparturePax) / totalAirlinesPax) *
          100
        ).toFixed(2),
        label: item.name,
      };
    });
    return mergedData?.sort((a, b) => b.value - a.value);
  }, [dataSource]);

  return (
    <Fragment>
      <MainCard>
        <Row
          style={{
            // alignItems: "center",
            justifyContent: "space-between",
            paddingTop: width >= 1920 ? 8 : 4,
            paddingLeft: 16,
            paddingRight: 16,
          }}
          gutter={[16]}
        >
          <Col span={24}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                className="title-onDoubleClick"
                style={{
                  fontWeight: "bold",
                  cursor: "pointer",
                  fontSize: fontSize.md,
                  color: colorPrimary,
                }}
              >
                {title} {loading && <Spin />}
              </Typography>
              <div
                style={{
                  padding: "4px 10px",
                  background: "#4a9b00",
                  borderRadius: 6,
                }}
              >
                <Typography style={{ color: "white", fontWeight: "bold" }}>
                  Avg LF : {"60"}%
                </Typography>
              </div>
            </div>
          </Col>
        </Row>
        <div
          style={{
            flexGrow: 1,
            position: "relative",
          }}
        >
          <div
            style={{
              margin: width >= 1920 ? "10px 10px" : "0px 10px",
              display: "flex",
              justifyContent: "space-around",
              // alignItems: "center",
              gap: 6,
              marginTop: width >= 1920 ? 10 : 0,
              height: "83%",
            }}
          >
            <div
              style={{
                padding: "4px",
                width: "50%",
                height: "100%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  padding: width >= 1920 ? 9 : 4,
                  background: colorPrimary,
                }}
              >
                <Typography
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Routes
                </Typography>
              </div>
              <div style={{ marginTop: width >= 1920 ? "20px" : "8px" }}>
                <SearchComponent
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  tempData={tempDataRoutes}
                  setMapData={setMapData}
                  classes={classes.customInput}
                  setSearchData={setSearchData}
                  searchData={searchData}
                />
              </div>
              <div
                style={{
                  height: "72%",
                  width: "100%",
                  marginTop: width >= 1920 ? 16 : 8,
                  overflowY: "auto",
                }}
              >
                {!!tempDataRoutes &&
                  Array.isArray(mapData) &&
                  mapData.length > 0 &&
                  mapData?.slice(0, 6).map((item, idx) => {
                    return (
                      <div
                        key={idx}
                        style={{
                          width: "100%",
                          padding: width >= 1920 ? "6px 10px" : "4px 8px",
                          background: "#BDBDBD",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: width >= 1920 ? 8 : 4,
                          cursor: "pointer",
                        }}
                        className={classes.LoadFactorStyle}
                      >
                        <Typography
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            color: "white",
                            fontSize: width >= 1920 ? "13px" : "8px",
                          }}
                        >
                          {item.label}
                        </Typography>
                        <Typography
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            color: "white",
                            fontSize: width >= 1920 ? "13px" : "8px",
                          }}
                        >
                          {item.value}%
                        </Typography>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div
              style={{
                padding: "4px",
                width: "50%",
                height: "100%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  padding: width >= 1920 ? 9 : 4,
                  background: colorPrimary,
                }}
              >
                <Typography
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Airlines
                </Typography>
              </div>
              <div style={{ marginTop: width >= 1920 ? "20px" : "8px" }}>
                <SearchComponent
                  searchTerm={searchTermAirlines}
                  setSearchTerm={setSearchTermAirlines}
                  tempData={tempDataAirlines}
                  setMapData={setMapDataAirlines}
                  classes={classes.customInput}
                  setSearchData={setSearchDataAirlines}
                  searchData={searchDataAirlines}
                />
              </div>
              <div
                style={{
                  height: "72%",
                  width: "100%",
                  marginTop: width >= 1920 ? 16 : 8,
                  overflowY: "auto",
                }}
              >
                {Array.isArray(mapDataAirlines) &&
                  mapDataAirlines.length > 0 &&
                  mapDataAirlines?.slice(0, 6).map((item, idx) => {
                    return (
                      <div
                        key={idx}
                        style={{
                          width: "100%",
                          padding: width >= 1920 ? "6px 10px" : "4px 8px",
                          background: "#BDBDBD",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: width >= 1920 ? 8 : 4,
                          cursor: "pointer",
                        }}
                        className={classes.LoadFactorStyle}
                      >
                        <Typography
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            color: "white",
                            fontSize: width >= 1920 ? "13px" : "8px",
                          }}
                        >
                          {item.label}
                        </Typography>
                        <Typography
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            color: "white",
                            fontSize: width >= 1920 ? "13px" : "8px",
                          }}
                        >
                          {item.value}%
                        </Typography>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </MainCard>
    </Fragment>
  );
};

export default LoadFactor;
