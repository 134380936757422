import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { levels } from "./levelConvert";
import { GetAPI } from "./apiHandling";
import moment from "moment";
import dayjs from "dayjs";
const Apps = createSlice({
  name: "apps",
  initialState: {
    errorMessage: "",
    levels,
    activePage: "",
    loading: false,
    airportCache: [],
    airportAp1Cache: [],
    airlineCache: [],
    jasterCache: [],
    configCache: {},
    roleCache: [],
    ws_uri: "",
    filterPage: {
      year: "2023",
      route: "",
      months: [],
    },
  },
  reducers: {
    setActivePage(state, payload) {
      state.activePage = payload.payload;
    },
    setLoading(state) {
      state.loading = true;
    },
    unsetLoading(state) {
      state.loading = false;
    },
    setAirportAp1(state, payload) {
      state.airportAp1Cache = payload.payload;
    },
    setAirline(state, payload) {
      state.airlineCache = payload.payload;
    },
    setConfig(state, payload) {
      state.configCache = payload.payload;
    },
    setRole(state, payload) {
      state.roleCache = payload.payload;
    },
    setWsUri(state, payload) {
      state.ws_uri = payload.payload;
    },
    setFilterPage(state, payload) {
      state.filterPage = { ...state.filterPage, ...payload.payload };
    },
    setJaster(state, payload) {
      state.jasterCache = payload.payload;
    },
  },
});

export const {
  setLoading,
  unsetLoading,
  setAirline,
  setAirport,
  setConfig,
  setAirportAp1,
  setActivePage,
  setRole,
  setWsUri,
  setFilterPage,
  setJaster,
} = Apps.actions;
export const loadAirportsAp1 = createAsyncThunk(
  "apps/load-airport-ap1",
  async (payload, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const {
      auth: { url: uri },
    } = getState();
    const url = uri + "api/airport/angkasa-pura-1";
    const resp = await dispatch(GetAPI({ url }));
    if (resp?.payload?.data) dispatch(setAirportAp1(resp.payload.data));
  }
);

export const loadAirlines = createAsyncThunk(
  "apps/load-airlines",
  async (payload, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const {
      auth: { url: uri },
    } = getState();
    const url = uri + "api/airlines/all";
    const resp = await dispatch(GetAPI({ url }));
    if (resp?.payload?.data) dispatch(setAirline(resp.payload.data));
  }
);

export const loadConfig = createAsyncThunk(
  "apps/load-config",
  async (payload, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const {
      auth: { url: uri },
    } = getState();
    const url = uri + "api/v1/apps/one";
    const resp = await dispatch(GetAPI({ url }));
    if (resp?.payload?.data) dispatch(setConfig(resp.payload.data));
  }
);

export const loadRole = createAsyncThunk(
  "apps/load-role",
  async (payload, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const {
      auth: { url: uri },
    } = getState();
    const url = uri + "api/v1/role/all";
    const resp = await dispatch(GetAPI({ url }));
    if (resp?.payload?.data) dispatch(setRole(resp.payload.data));
  }
);

export const loadWsUri = createAsyncThunk(
  "apps/get-ws-uri",
  async (payload, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const {
      auth: { url: uri },
    } = getState();
    const url = uri + "wsuri";
    const resp = await dispatch(GetAPI({ url }));
    console.log({ url, pl: resp.payload.data });
    if (!!resp?.payload?.data) {
      dispatch(setWsUri(resp.payload.data));
    }
  }
);

export const loadJaster = createAsyncThunk(
  "apps/load-jaster",
  async (payload, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const {
      auth: { url: uri },
    } = getState();
    const url = uri + "api/v1/cto/all";
    const resp = await dispatch(GetAPI({ url }));
    if (resp?.payload?.data) dispatch(setJaster(resp.payload.data));
  }
);

export default Apps.reducer;
