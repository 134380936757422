import React, { Fragment } from "react";
import { Routing } from "../routes";
import { Layout, Typography } from "antd";
import { Route, Routes } from "react-router-dom";
import LayoutComponent from "../../../components/Layout";
import Filter from "../../../components/Filter";
import { useDispatch, useSelector } from "react-redux";
import { useFontSize } from "../../../hooks/useDimension";
import { setFilterPage } from "../../../redux/reducer/apps";
import moment from "moment";

const { Content } = Layout;

const TheRouter = ({ isMobileView }) => {
  const workplace = "cabang";
  const Routers = Routing(isMobileView);
  const route = Routers.filter((r) => r.level.indexOf(workplace) >= 0);
  return (
    <Routes>
      {route.map((route, idx) => (
        <Route
          key={idx}
          path={route.to}
          element={!!route.component ? route.component : <div>NotFound</div>}
        />
      ))}
      <Route path={"/*"} element={<div>Page Not Found</div>} />
    </Routes>
  );
};

const MemberPage = ({ isMobileView }) => {
  const { filterPage } = useSelector((state) => state.apps);
  const fontSize = useFontSize();
  const dispatch = useDispatch();

  const handleOnChange = (type, value) => {
    switch (type) {
      case "year":
        // setState((state) => ({ ...state, year: value }));
        dispatch(
          setFilterPage({
            year: value,
          })
        );
        break;
      case "months":
        // setState((state) => ({ ...state, months: value }));
        dispatch(setFilterPage({ months: value }));
        break;
      case "route":
        if (filterPage.route === value) {
          // setState((state) => ({ ...state, route: "" }));
          dispatch(setFilterPage({ route: "" }));
        } else {
          // setState((state) => ({ ...state, route: value }));
          dispatch(setFilterPage({ route: value }));
        }
        break;
      default:
        break;
    }
  };
  return (
    <Fragment>
      <LayoutComponent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div style={{ flex: "0%" }}>
            <Filter state={filterPage} onChange={handleOnChange} />
          </div>
          <div
            style={{
              flex: "90%",
              flexGrow: 1,
              padding: fontSize.xs,
              background: "#EDEDED",
            }}
          >
            <Content
              style={{
                height: "100%",
                // padding: "10px 20px",
              }}
            >
              <TheRouter isMobileView={isMobileView} />
            </Content>
          </div>
        </div>
      </LayoutComponent>
    </Fragment>
  );
};

export default MemberPage;
