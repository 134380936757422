import { Tooltip } from "antd";
import { HiOutlinePresentationChartBar } from "react-icons/hi";
import { Link } from "react-router-dom";
import { GiCommercialAirplane } from "react-icons/gi";

export const items = ({ fontSize, isMobile = false, workplace }) => [
  {
    key: "Overview",
    workplaces: [],
    icon: (
      <Tooltip
        zIndex={9999}
        placement={isMobile ? "left" : "right"}
        title="Overview"
      >
        <Link to={"/dashboard/overview"}>
          <HiOutlinePresentationChartBar size={fontSize} />
        </Link>
      </Tooltip>
    ),
    href: "/dashboard/overview",
  },
  {
    key: "Stakeholders",
    workplaces: [],
    icon: (
      <Tooltip
        zIndex={9999}
        placement={isMobile ? "left" : "right"}
        title="Stakeholders"
      >
        <Link to={"/dashboard/stakeholders"}>
          <GiCommercialAirplane size={fontSize} />
        </Link>
      </Tooltip>
    ),
    href: "/dashboard/stakeholders",
  },
];
