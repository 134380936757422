import { Input } from "antd";
import React, { useEffect } from "react";
import { SearchOutlined } from "@ant-design/icons";
import useWindowDimensions from "../../../hooks/useDimension";

const SearchComponent = ({
  searchTerm,
  setSearchTerm,
  tempData,
  setMapData,
  classes,
  setSearchData,
  searchData,
}) => {
  const { width } = useWindowDimensions();
  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleSearchRuoutes = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const debouncedSearch = debounce((value) => {
    // Perform your search operation here with the value
    if (value) {
      const onSearch = tempData?.filter((item) => {
        return item.label?.toLowerCase().includes(value?.toLowerCase());
      });

      setSearchData(onSearch);
    }
  }, 500); // Adjust the debounce delay (in milliseconds) as needed

  useEffect(() => {
    // Clean up the debounce function on component unmount

    return () => {
      clearTimeout(debouncedSearch);
    };
  }, [debouncedSearch]);

  useEffect(() => {
    if (searchTerm) {
      setMapData(searchData);
    } else {
      setMapData(tempData);
    }
  }, [searchTerm, searchData, tempData, setMapData]);

  return (
    <Input
      size={width >= 1920 ? "large" : "small"}
      placeholder="Type to search"
      prefix={<SearchOutlined />}
      className={classes.customInput}
      value={searchTerm}
      onChange={handleSearchRuoutes}
    />
  );
};

export default SearchComponent;
