import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import useWindowDimensions from "../hooks/useDimension";
import Dashboard from "./Dashboard";
import Landing from "./Dashboard/pages/landing";

const MAX_RES_MOBILE = 1200;

const MainRoutes = ({ isMobileView }) => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<div>Login</div>} />
        <Route path="/dashboard/*" element={<Dashboard />} />
      </Routes>
    </Router>
  );
};

const MainRouter = () => {
  const { width } = useWindowDimensions();

  const isMobileView = useMemo(() => {
    return width < MAX_RES_MOBILE;
  }, [width]);
  return <MainRoutes isMobileView={isMobileView} />;
};

export default MainRouter;
