import { useState, useEffect } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const defFontSize = {
  xl3:36,
  xl2:30,
  xl: 24,
  lg: 18,
  md: 14,
  sm: 12,
  xs: 10,
  xxs: 8
};

export const useFontSize = () => {
  const { width, height } = useWindowDimensions();
  const [fontSize, setFontSize] = useState(defFontSize);

  useEffect(() => {
    const adder=Math.round((1400 - width)/100);
    if(width > 1200){
      setFontSize({
        xl3:defFontSize.xl3 - adder,
        xl2:defFontSize.xl2 - adder,
        xl:defFontSize.xl - adder,
        lg:defFontSize.lg - adder,
        md:defFontSize.md - adder,
        sm:defFontSize.sm - adder,
        xs: defFontSize.xs - adder,  
        xxs:defFontSize.xxs - adder
      })
    }
    // if (width >= 1920) {
    //   setFontSize(defFontSize);
    // } else if (width <= 1600 && width > 1500) {
    //   setFontSize({
    //     lg: "20px",
    //     md: "18px",
    //     sm: "14px",
    //     xs: "12px",
    //     xl: "24px",
    //   });
    // } else if (width <= 1536 && width > 1280) {
    //   setFontSize({
    //     lg: "24px",
    //     md: "20px",
    //     sm: "15px",
    //     xs: "13px",
    //     xl: "26px",
    //   });
    // } else if (width <= 1280) {
    //   setFontSize({
    //     lg: "18px",
    //     md: "14px",
    //     sm: "10px",
    //     xs: "9px",
    //     xl: "20px",
    //   });
    // }
  }, [width]);

  return fontSize;
};

export const useHeightCard = (sm, md, lg, xl) => {
  const { width } = useWindowDimensions();
  const [heightCard, setHeightCard] = useState(sm);

  useEffect(() => {
    if (width >= 1920) {
      setHeightCard(xl);
    } else if (width <= 1745 && width > 1536) {
      setHeightCard(lg);
    } else if (width <= 1536 && width > 1280) {
      setHeightCard(md);
    } else if (width <= 1280) {
      setHeightCard(sm);
    }
  }, [width]);

  return heightCard;
};

export const useLayoutCustomize = (
  heightSm,
  heightMd,
  heightLg,
  heightXl,
  widthSm,
  widthMd,
  widthLg,
  widthXl
) => {
  const { width, height } = useWindowDimensions();
  const [layout, setLayout] = useState({
    width: widthSm,
    height: heightSm,
  });
  useEffect(() => {
    if (width >= 1920) {
      setLayout({ height: heightXl, width: widthXl });
    } else if (width <= 1745 && width > 1536) {
      setLayout({ height: heightLg, width: widthLg });
    } else if (width <= 1536 && width > 1280) {
      setLayout({ height: heightMd, width: widthMd });
    } else if (width <= 1280) {
      setLayout({ height: heightSm, width: widthSm });
    }
  }, [width, height]);

  return layout;
};
