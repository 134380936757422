import React, { Fragment, useEffect, useMemo, useState } from "react";
import MainChart from "../../../components/Chart/MainChart";
import { useFontSize } from "../../../hooks/useDimension";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import Config from "../../../config/env";
import { useDashboardOverview } from "../../../hooks/useDashboardV2";
const { colorPrimary } = Config;

const selectOpt = [
  { value: "bar", label: "Bar Chart" },
  { value: "line", label: "Line Chart" },
  { value: "scatter", label: "Scatter Chart" },
];

const TrendChart = ({ title, dataSource, loading }) => {
  const { filterPage: filterValue } = useSelector((state) => state.apps);
  const [chartType, setChartType] = useState("bar");

  const fontSize = useFontSize();
  const months = useMemo(() => {
    return dataSource?.map((item) => item.monthName.slice(0, 3));
  }, [dataSource]);

  const reducedData = dataSource?.map((item) => {
    const reducedDep = Object.keys(item.pax.dep).reduce(
      (acc, key) => acc + item.pax.dep[key],
      0
    );
    const reducedArr = Object.keys(item.pax.arr).reduce(
      (acc, key) => acc + item.pax.arr[key],
      0
    );

    return {
      month: item.month,
      monthName: item.monthName,
      value: reducedArr + reducedDep,
    };
  });

  const option = {
    grid: {
      x: fontSize.xl * 4,
      x2: fontSize.xl * 4,
      y: fontSize.xs,
      y2: fontSize.lg,
      //   top: 5,
      //   bottom: 10,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    visualMap: {
      show: false,
      orient: "horizontal",
      left: "center",
      min: 10,
      max: 0,
      // Map the score column to color
      dimension: 0,
      inRange: {
        color: ["#65B581", "#FFCE34", "#FD665F"],
      },
    },
    // legend: {
    //   bottom: "bottom",
    // },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "10%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      // data: [],
      data: months || [],
      axisTick: {
        show: true,
      },
      axisLine: {
        show: true,
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        show: false,
      },
      splitLine: {
        show: true,
      },
    },
    series: [
      {
        name: "Real 2023",
        type: chartType,
        data:
          reducedData?.map((item) => ({
            value: item.value,
            itemStyle: {
              opacity:
                filterValue?.months?.length === 0
                  ? 1
                  : filterValue?.months.includes(item.month)
                  ? 1
                  : 0.3,
            },
          })) || [],
        color: "#004893",
        itemStyle: {
          borderRadius: [4, 4, 0, 0],
        },
        barWidth: "40%",
      },
    ],
  };

  return (
    <MainChart
      isPaddingBottom
      title={
        <Fragment>
          {title} {loading && <Spin />}
        </Fragment>
      }
      height={fontSize.md * 23}
      option={option}
      filterComp={selectOpt}
      defaultValue={"bar"}
      onChange={(e) => setChartType(e)}
    />
  );
};

export default TrendChart;
