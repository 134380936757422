import React, { Fragment, useMemo } from "react";
import MainChart from "../../../components/Chart/MainChart";
import useWindowDimensions, { useFontSize } from "../../../hooks/useDimension";
import { Spin } from "antd";
import numberSparator from "../../../helper/numberSparator";

const PayingPaxProportion = ({ title, dataSource, loading }) => {
  const fontSize = useFontSize();
  const { width } = useWindowDimensions();

  const trafficData = useMemo(() => {
    const arrTotal =
      dataSource?.arr.adult +
      dataSource?.arr.child +
      dataSource?.arr.crew +
      dataSource?.arr.infant +
      dataSource?.arr.transfer +
      dataSource?.arr.transit;
    const depTotal =
      dataSource?.dep.adult +
      dataSource?.dep.child +
      dataSource?.dep.crew +
      dataSource?.dep.infant +
      dataSource?.dep.transfer +
      dataSource?.dep.transit;
    return arrTotal + depTotal;
  }, [dataSource]);

  const payingData = useMemo(() => {
    const arrTotal = dataSource?.arr.adult + dataSource?.arr.child;

    const depTotal = dataSource?.dep.adult + dataSource?.dep.child;
    return arrTotal + depTotal;
  }, [dataSource]);

  const percentage = useMemo(() => {
    return ((payingData / trafficData) * 100).toFixed(2);
  }, [dataSource]);

  const option = {
    title: {
      // text: "World Population",
    },
    tooltip: {
      formatter: (params) => `
      <div>
        <p style=\"font-weight:bold\">Paying Pax Proportion Chart</p>
        <p><span>${params[0].marker}${
        params[0]?.seriesName
      } : <span style=\"font-weight:bold\">${numberSparator(
        params[0]?.value
      )}</span>
        </p>
        <p><span>${params[1].marker}${
        params[1]?.seriesName
      } : <span style=\"font-weight:bold\">${numberSparator(
        params[1]?.value
      )}</span>
          </p>
      </div>
      `,
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      bottom: "bottom",
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "10%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      boundaryGap: [0, 0.01],
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        inside: true,
        color: "#fff",
      },
    },
    yAxis: {
      splitLine: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        inside: true,
        color: "#fff",
      },
    },
    graphic: [
      {
        type: "group",
        left: "40%",
        bottom: "20%",
        children: [
          {
            type: "rect",
            z: 100,
            left: "center",
            top: "middle",
            shape: {
              width: width >= 1920 ? 180 : 140,
              height: width >= 1920 ? 90 : 50,
            },
            style: {
              fill: "#CD7F32",
              stroke: "none",
              lineWidth: 1,
              shadowBlur: 8,
              shadowOffsetX: 3,
              shadowOffsetY: 3,
              shadowColor: "rgba(0,0,0,0.2)",
            },
          },
          {
            type: "text",
            z: 100,
            left: "center",
            top: "middle",
            style: {
              fill: "white",
              width: 220,
              overflow: "break",
              text: percentage !== "NaN" ? `${percentage}%` : "Loading . . .",
              font:
                width >= 1920
                  ? percentage !== "NaN"
                    ? "45px Microsoft YaHei"
                    : "15px Microsoft YaHei"
                  : percentage !== "NaN"
                  ? "35px Microsoft YaHei"
                  : "10px Microsoft YaHei",
            },
          },
        ],
      },
    ],
    series: [
      {
        name: "Traffic",
        type: "bar",
        data: [trafficData],
        barWidth: "20%",
        color: "#FFD700",
      },
      {
        name: "Paying",
        type: "bar",
        data: [payingData],
        barWidth: "20%",
        color: "#C0C0C0",
      },
    ],
  };

  return (
    <MainChart
      title={
        <Fragment>
          {title} {loading && <Spin />}
        </Fragment>
      }
      height={fontSize.md * 17}
      option={option}
      otherComponent={() => <div style={{}}></div>}
    />
  );
};

export default PayingPaxProportion;
