import React, { useMemo } from "react";
import { Layout, Menu, DatePicker, Typography } from "antd";
import { createUseStyles } from "react-jss";
import Config from "../config/env";
import { useDispatch, useSelector } from "react-redux";
import { useFontSize } from "../hooks/useDimension";
import { useLocation } from "react-router-dom";
import { doLogout, logout, setActivePage } from "../redux";
import { items } from "./items";

const { Sider } = Layout;
const { Theme, colorPrimary } = Config;

const useStyles = createUseStyles({
  container: {
    minHeight: "100vh",
    overflow: "hidden !important",
    display: "flex",
    width: "100vw",
    // position: "relative"
  },
  content: {
    height: "100vh",
    background: "#EDEDED",
  },
  menuStyle: {
    paddingTop: ({ fontSize }) => fontSize.xl3 + 15,

    background: "none",

    "&.ant-menu-dark": {
      background: "none",
    },
    "& .ant-menu-item": {
      padding: ({ fontSize }) => `${fontSize.lg}px 12px !important`,
      marginLeft: "12px !important",
      marginBottom: ({ fontSize }) => `${fontSize.md}px !important`,
    },
    "&.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline": {
      // marginLeft: 4,
      background: "none",
      padding: ({ fontSize }) => fontSize.md,
    },
    "&.ant-menu-dark .ant-menu-item-selected": {
      marginLeft: "16px !important",
      backgroundColor: "#ededed",
      fontWeight: "bold",
      color: colorPrimary,
      width: "80px",
      padding: ({ fontSize }) => fontSize.sm,
      "&:active": {
        backgroundColor: "red",
      },
      // margin: 0
    },
  },

  siderStyle: {
    // paddingTop: 170,
    height: "100vh",
    // position: "fixed !important",
    // top: 0,
    // bottom: 0,
    // left: 0,
    // zIndex: 99,
    background: `${colorPrimary} !important`,
  },
});

const CustomLayout = ({ children, ...props }) => {
  const fontSize = useFontSize();
  const dispatch = useDispatch();
  const { workplace, userdata } = useSelector((state) => state.auth);
  const classes = useStyles({ ...props, fontSize });
  const { pathname } = useLocation();

  const MenusItem = useMemo(() => {
    return items({ fontSize: fontSize.xl, workplace });
  }, [workplace, userdata]);

  return (
    <Layout className={classes.container}>
      <Sider
        className={`${classes.siderStyle} sider-dashboard`}
        width={fontSize.xl3 * 2}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: fontSize.sm,
              }}
            >
              {/* <div>
                <Typography>ESI AODB</Typography>
              </div> */}
              <Menu
                style={{ marginBottom: fontSize }}
                className={classes.menuStyle}
                theme="dark"
                defaultSelectedKeys={MenusItem[0].href}
                onClick={(e) => {
                  dispatch(setActivePage(e.key));
                }}
                mode="inline"
                selectedKeys={MenusItem.map((el) => {
                  if (el.href === pathname) return el.key;
                })}
                items={MenusItem}
              />
            </div>
          </div>
        </div>
      </Sider>
      <div style={{ height: "100vh", width: "100%" }}>{children}</div>
    </Layout>
  );
};

export default CustomLayout;
